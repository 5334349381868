<template>
  <div>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5"
          >{{ propEditMode == true ? "Edit " : "Add " }} property
        </v-card-title>

        <div style="margin-left: 16px; margin-right: 16px">
          <v-form ref="form1" v-model="validP" lazy-validation>
            <v-select
              :readonly="propEditMode"
              v-on:change="getAreas()"
              v-model="selectedProperty"
              :items="['cs', 'six']"
              label="Property"
            ></v-select>
            <v-select
              :readonly="propEditMode"
              v-model="assignedArea"
              item-value="room"
              :items="areas"
              label="Select Area"
              required
              :rules="[(v) => !!v || 'Area is required']"
              v-on:change="callGetOwnerInfo()"
              no-data-text="Fetching data. Please wait."
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <span>Room#: {{ item.room }}, Owner: {{ item.name }}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="selection" slot-scope="data">
                Room#: {{ data.item.room }}, Owner:
                {{ data.item.name }}
              </template>
            </v-select>
            <v-text-field
              :readonly="propEditMode"
              v-if="selectedProperty == 'cs'"
              v-model="roomtypecode"
              label="Room Type Code"
              :rules="[(v) => !!v || 'Room type Code is required']"
              required
            ></v-text-field>

            <v-text-field
              v-model="payableId"
              label="Payable Id"
              type="number"
              :rules="[(v) => !!v || 'Payable Id is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="receivableId"
              label="Receivable Id"
              type="number"
              :rules="[(v) => !!v || 'Receivable Id is required']"
              required
            ></v-text-field>
          </v-form>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!validP || loadingP"
            color="success"
            class="mr-4"
            @click="validateP"
            :loading="loadingP"
          >
            Save Property
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add a New App User</h3>
            </div>
          </div>

          <div class="card-body">
            <!--begin::Form-->
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="loading-div" v-if="isDisable">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="#0abb87"
                  indeterminate
                ></v-progress-circular>
              </div>
              <!-- <v-select v-model="assignedArea" item-value="room" :items="areas" label="Select Area" required
                :rules="[(v) => !!v || 'Area is required']" v-on:change="callGetOwnerInfo()"
                no-data-text="Fetching data. Please wait.">
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <span>Room#: {{ item.room }}, Owner:
                        {{ item.name }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template slot="selection" slot-scope="data">
                  Room#: {{ data.item.room }}, Owner:
                  {{ data.item.name }}
                </template>
              </v-select> -->
              <v-text-field
                v-model="email"
                label="Email"
                :rules="emailRules"
                :readonly="editMode == true"
                required
              ></v-text-field>
              <v-text-field
                v-model="name"
                label="Name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
              <v-text-field v-model="phone" label="Phone No"></v-text-field>

              <!-- <v-text-field v-model="roomtypecode" label="Room Type Code"  :rules="[(v) => !!v || 'Room type Code is required']"
                required></v-text-field> -->
              <!-- <v-text-field v-model="payableId" label="Payable Id" type="number"
                :rules="[(v) => !!v || 'Payable Id is required']" required></v-text-field>
              <v-text-field v-model="receivableId" label="Receivable Id" type="number"
                :rules="[(v) => !!v || 'Receivable Id is required']" required></v-text-field> -->
              <div class="fv-plugins-message-container">
                <div class="fv-help-block errorMsg">
                  {{ error }}
                </div>
                <div
                  v-show="elementVisible"
                  class="fv-help-block successMsg"
                  v-if="successMsg != ''"
                >
                  {{ successMsg }}
                </div>
              </div>
              <v-btn
                :disabled="!valid || loading || isDisable"
                color="success"
                class="mr-4"
                @click="validate"
                :loading="loading"
              >
                {{ editMode ? "Update User" : "Add User" }}
              </v-btn>
            </v-form>
            <!--end::Form-->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">All App Users</h3>
            </div>
          </div>
          <div class="card-body">
            <!--begin::Table-->

            <v-card-title>
              <div class="row search-bar">
                <div class="col-md-4">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
              </div>
            </v-card-title>
            <v-data-table :headers="headers" :items="allUsers" :search="search">
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="loadUser(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <template v-slot:[`item.delete`]="{ item }">
                <v-icon small class="mr-2" @click="deleteUserConfirmation(item)"
                  >mdi-delete</v-icon
                >
              </template>

              <template v-slot:[`item.copy`]="{ item }">
                <v-icon small class="mr-2" @click="copy(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <template v-slot:[`item.properties`]="{ item }">
                <!-- {{ item.areas_.join("|") }} -->

                <!--begin::Item-->
                <div style="display: flex">
                  <div style="display: flex">
                    <div v-for="(item_, i) in item.areas">
                      <v-chip
                        small
                        color="green"
                        text-color="white"
                        class="ma-2"
                        close
                        @click="editProp(item_, item.id)"
                        @click:close="DeleteConfirmProp(item_.id, item.id)"
                      >
                        {{
                          item_.from.toUpperCase() +
                          "-" +
                          item_.areaname.toString().replace("Villa", "")
                        }}
                      </v-chip>
                    </div>
                  </div>

                  <v-icon small class="mr-2" @click="addProperty(item)"
                    >mdi-plus</v-icon
                  >
                </div>
              </template>
            </v-data-table>

            <!--end::Table-->
          </div>
        </div>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog v-model="confirmDialog" max-width="290">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to <br />
            Delete this User?</v-card-title
          >

          <v-card-text> This process can not be undone. </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="confirmDialog = false">
              Cancel
            </v-btn>

            <v-btn color="green darken-1" text @click="callDeleteUser">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDialogP" max-width="290">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to <br />
            remove this property?</v-card-title
          >

          <v-card-text> This process can not be undone. </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="confirmDialogP = false">
              Cancel
            </v-btn>

            <v-btn color="green darken-1" text @click="callDeleteProperty">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style lang="scss" scoped>
.successMsg {
  color: #28a745;
}

.errorMsg {
  color: #dc3545;
}

.fv-help-block {
  padding-bottom: 10px;
  font-size: 15px;
}

.search-bar {
  justify-content: flex-end;
}

.loading-div {
  height: 65%;
  width: 100%;
  z-index: 1;
  // background: red;
  position: absolute;
  bottom: 60px;
  // opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getDatabase,
  ref,
  child,
  get,
  set,
  update,
  push,
} from "firebase/database";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const { XMLParser } = require("fast-xml-parser");
const dbRef = ref(getDatabase());
const functions = getFunctions();
const auth = getAuth();
import moment from 'moment';
export default {
  data: () => ({
    confirmDialog: false,
    confirmDialogP: false,

    valid: true,
    validP: true,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    email: "",
    name: "",
    phone: "",
    roomtypecode: "",
    payableId: "",
    receivableId: "",
    error: "",
    successMsg: "",
    dialog: false,
    editMode: false,
    propEditMode: false,
    loading: false,
    loadingP: false,
    selectedProperty: null,
    elementVisible: true,
    search: "",
    userareas: [],
    userareasfordelete: [],
    headers: [
      {
        text: "Name",
        align: "left",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Password", value: "password" },
      { text: "Phone No", value: "phone" },
      // { text: "Area Name", value: "areaname" },
      // { text: "Payable Id", value: "paybleid" },
      // { text: "Receivable Id", value: "receivabalid" },
      { text: "Properties", value: "properties" },
      { text: "Edit", value: "actions" },
      { text: "Delete", value: "delete" },
      // { text: "Copy", value: "copy" },
    ],

    allUsers: [],
    selectedUserId: "",
    selectedAreaId: "",
    areas: [],
    assignedArea: "",
    ownerInfo: [],
    guestId: "",
    categoryId: "",
    areaName: "",
    isDisable: false,
  }),
  components: {},
  computed: {},
  mounted() {
    this.getAllUsers();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "add-user" },
      // { title: "Add User  " },
    ]);
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.editMode = false;
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editMode) {
          this.editUser();
        } else {
          this.addUser();
        }
      }
    },
    validateP() {
      if (this.$refs.form1.validate()) {
        this.saveProperty();
      }
    },
    getAreas() {
      this.areas = [];
      if (this.selectedProperty == "cs") {
        this.callGetAreas();
      } else if (this.selectedProperty == "six") {
        this.callGetAreasForSix();
      }
    },
    async callGetAreasForSix() {
      let requests = [];
      get(child(dbRef, `roomlistsix/`))
        .then((snapshot) => {
          // alert(JSON.stringify(snapshot.val()));
          this.areas = snapshot.val();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async callGetAreas() {
      let requests = [];
      get(child(dbRef, `roomlist/`))
        .then((snapshot) => {
          // alert(JSON.stringify(snapshot.val()));
          this.areas = snapshot.val();
          // if (snapshot.exists()) {
          //   snapshot.forEach((child) => {
          //     if (child.val().type == "appuser") {
          //       requests.push({ ...child.val(), id: child.key });
          //     }
          //   });
          //   this.allUsers = requests;
          // } else {
          //   console.log("No data available");
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    ValidateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      // alert("You have entered an invalid email address!")
      return false;
    },
    // async addUser_() {
    //   // alert(this.roomtypecode)
    //   // return

    //   if (!this.ValidateEmail(this.email)) {
    //     this.error = "Invalid email";
    //     return;
    //   }
    //   this.loading = true;
    //   const functions = getFunctions();

    //   let guestRecord = {
    //     birthdate: "2000-01-01",
    //     name: this.name,
    //     phone: this.phone,
    //     email: this.email,
    //   };

    //   let createGuest = httpsCallable(functions, "createGuest");
    //   createGuest(guestRecord)
    //     .then((result) => {
    //       // Read result of the Cloud Function.

    //       const options = {
    //         ignoreAttributes: false,
    //       };
    //       const parser = new XMLParser(options);
    //       let jObj = parser.parse(result.data);

    //       try {
    //         if (this.email !== "" && this.name !== "") {
    //           let userRecord = {
    //             email: this.email,
    //             // password:this.password,
    //             name: this.name,
    //             phone: this.phone,
    //             roomtypecode: this.roomtypecode,

    //             type: "appuser",
    //             areaId: this.assignedArea,
    //             categoryId: "",
    //             guestId:
    //               jObj.OTA_HotelProfileNotifRS.TPA_Extensions.TPA_GuestCode[
    //                 "@_GuestCode"
    //               ],
    //             areaName: this.areaName,
    //             payableId: this.payableId,
    //             receivableId: this.receivableId,
    //           };

    //           let createUser = httpsCallable(functions, "createUser");
    //           createUser(userRecord)
    //             .then((result) => {
    //               // Read result of the Cloud Function.
    //               this.loading = false;
    //               console.log(result);
    //               if (result.data.code == 1 || result.data.code == 3) {
    //                 this.error = result.data.response;
    //               } else if (result.data.code == 2) {
    //                 this.getAllUsers();
    //                 this.successMsg = result.data.response;
    //                 setTimeout(() => (this.elementVisible = false), 2000);
    //                 this.reset();
    //               }
    //             })
    //             .catch((error) => {
    //               console.log(error.message);
    //             });
    //         }
    //       } catch (error) {
    //         this.error = error.message;
    //       } finally {
    //         // this.loading = false;
    //         this.error = "";
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.message);
    //     });
    //   return;

    //   // if (this.$refs.form.validate()) {
    //   // this.loading = true;

    //   // }
    // },
    async addUser() {
      // alert(this.roomtypecode)
      // return

      if (!this.ValidateEmail(this.email)) {
        this.error = "Invalid email";
        return;
      }
      this.loading = true;

      const functions = getFunctions();
      let userRecord = {
        email: this.email,
        // password:this.password,
        name: this.name,
        phone: this.phone,
        //  roomtypecode:"",

        type: "appuser",
        //  areaId: "",
        //  categoryId: "",
        //  guestId: "",
        //  areaName: "",
        //  payableId:  "",
        //  receivableId: "",
      };

      let createUser = httpsCallable(functions, "createUser");
      createUser(userRecord)
        .then((result) => {
          // Read result of the Cloud Function.
          this.logData("New user created-"+this.email)
          this.loading = false;
          console.log(result);
          if (result.data.code == 1 || result.data.code == 3) {
            this.error = result.data.response;
          } else if (result.data.code == 2) {
            this.getAllUsers();
            this.successMsg = result.data.response;
            setTimeout(() => (this.elementVisible = false), 2000);
            this.reset();
          }
        })
        .catch((error) => {
          console.log(error.message);
        });

      return;

      let guestRecord = {
        birthdate: "2000-01-01",
        name: this.name,
        phone: this.phone,
        email: this.email,
      };

      let createGuest = httpsCallable(functions, "createGuest");
      createGuest(guestRecord)
        .then((result) => {
          // Read result of the Cloud Function.

          const options = {
            ignoreAttributes: false,
          };
          const parser = new XMLParser(options);
          let jObj = parser.parse(result.data);

          try {
            if (this.email !== "" && this.name !== "") {
              let userRecord = {
                email: this.email,
                // password:this.password,
                name: this.name,
                phone: this.phone,
                roomtypecode: this.roomtypecode,

                type: "appuser",
                areaId: this.assignedArea,
                categoryId: "",
                guestId:
                  jObj.OTA_HotelProfileNotifRS.TPA_Extensions.TPA_GuestCode[
                    "@_GuestCode"
                  ],
                areaName: this.areaName,
                payableId: this.payableId,
                receivableId: this.receivableId,
              };

              let createUser = httpsCallable(functions, "createUser");
              createUser(userRecord)
                .then((result) => {
                  // Read result of the Cloud Function.
                  this.loading = false;
                  console.log(result);
                  if (result.data.code == 1 || result.data.code == 3) {
                    this.error = result.data.response;
                  } else if (result.data.code == 2) {
                    this.getAllUsers();
                    this.successMsg = result.data.response;
                    setTimeout(() => (this.elementVisible = false), 2000);
                    this.reset();
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            }
          } catch (error) {
            this.error = error.message;
          } finally {
            // this.loading = false;
            this.error = "";
          }
        })
        .catch((error) => {
          console.log(error.message);
        });

      return;

      // if (this.$refs.form.validate()) {
      // this.loading = true;

      // }
    },
    async getAllUsers() {
      let requests = [];
      get(child(dbRef, `users/`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((child) => {
              if (child.val().type == "appuser") {
                let properties = [];
                let areas = [];
                if (child.val().areas) {
                  let keys = Object.keys(child.val().areas);
                  keys.forEach((element) => {
                    let obj = child.val().areas[element];
                    obj.id = element;
                    properties.push(obj.from);
                    areas.push(obj);
                  });
                  // Object.values(child.val().areas).forEach((element) => {
                  //   let obj = element;
                  //   obj.id = child.key;
                  //   properties.push(element.from);
                  //   areas.push(obj);
                  // });
                }

                requests.push({
                  ...child.val(),
                  id: child.key,
                  areas_: properties,
                  areas: areas,
                });
              }
            });
            this.allUsers = requests;
            console.log(requests);
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteUserConfirmation(user) {
      this.selectedUserId = user.id;
      this.confirmDialog = true;
    },
    DeleteConfirmProp(aid, uid) {
      this.selectedUserId = uid;
      this.selectedAreaId = aid;
      this.confirmDialogP = true;
    },
    editProp(prop, uid) {
      // this.$refs?.form1.reset();
      this.selectedAreaId = prop.id;
      this.propEditMode = true;
      this.selectedUserId = uid;
      this.selectedProperty = prop.from;
      if (prop.from == "cs") {
        this.callGetAreas();
      } else if (prop.from == "six") {
        this.callGetAreasForSix();
      }

      this.assignedArea = prop.areaid;
      this.payableId = prop.paybleid;
      this.receivableId = prop.receivabalid;
      this.roomtypecode = prop.roomtypecode;
      this.dialog = true;
    },
    addProperty(user) {
      // this.$refs?.form1.reset();
      this.editMode = false;

      this.propEditMode = false;
      this.selectedUserId = user.id;

      this.name = user.name;
      this.email = user.email;
      this.phone = user.phone;
      this.userareas = user.areas_;
      this.dialog = true;
    },
    saveProperty() {
      if (this.propEditMode) {
        this.loading=true
        const db = getDatabase();
        update(ref(db,"users/" + this.selectedUserId + "/areas/"+this.selectedAreaId), {
          paybleid: this.payableId,
                receivabalid: this.receivableId,
        })
          .then(() => {
            this.logData("Area("+this.selectedAreaId+") edited on user-" +this.selectedUserId)
            this.getAllUsers();
            this.reset();
            this.loading = false;
            this.editMode = false;
            this.propEditMode=false
            selectedUserId = "";
            this.selectedAreaId=""
            this.$refs.form1.reset();
          })
          .catch((error) => {
            this.loading=false
            // The write failed...
          });
      } else {
        if (this.userareas.includes(this.selectedProperty)) {
          alert("exists");
          return;
        }
        const db = getDatabase();

        if (this.selectedProperty == "cs") {
         
              
          this.loadingP = true;
          let guestRecord = {
            birthdate: "2000-01-01",
            name: this.name,
            phone: this.phone,
            email: this.email,
          };

          console.log( {
                areaid: this.assignedArea,
                areaname: this.areaName,
                from: "cs",
                guestId:
                  "",
                paybleid: this.payableId,
                receivabalid: this.receivableId,
                roomtypecode: this.roomtypecode,
                categoryid: this.categoryId,
              })

          let createGuest = httpsCallable(functions, "createGuest");
          createGuest(guestRecord)
            .then((result) => {
              // Read result of the Cloud Function.

              const options = {
                ignoreAttributes: false,
              };
              const parser = new XMLParser(options);
              let jObj = parser.parse(result.data);

              console.log( {
                areaid: this.assignedArea,
                areaname: this.areaName,
                from: "cs",
                guestId:
                  "",
                paybleid: this.payableId,
                receivabalid: this.receivableId,
                roomtypecode: this.roomtypecode,
                categoryid: this.categoryId,
              })


              push(ref(db, "users/" + this.selectedUserId + "/areas"), {
                areaid: this.assignedArea,
                areaname: this.areaName,
                from: "cs",
                guestId:
                  jObj.OTA_HotelProfileNotifRS.TPA_Extensions.TPA_GuestCode[
                    "@_GuestCode"
                  ],
                paybleid: this.payableId,
                receivabalid: this.receivableId,
                roomtypecode: this.roomtypecode,
                categoryid: this.categoryId,
              });
              this.logData("New area("+this.assignedArea+") created on user-" +this.selectedUserId)
              this.$refs?.form1.reset();
              this.loadingP = false;
              this.getAllUsers();
            })
            .catch((error) => {
              console.log(error.message);
            });
        } else if (this.selectedProperty == "six") {
          this.loadingP = true;
          push(ref(db, "users/" + this.selectedUserId + "/areas"), {
            areaid: this.assignedArea,
            areaname: this.areaName,
            from: "six",
            guestid: "",
            paybleid: this.payableId,
            receivabalid: this.receivableId,
            roomtypecode: "",
            categoryid: "",
          });
          this.logData("New area("+this.assignedArea+") created on user-" +this.selectedUserId)
          this.$refs?.form1.reset();
          this.loadingP = false;
          this.getAllUsers();
        }
      }

      this.dialog = false;
     
    },

    async callDeleteUser() {
      // console.log(user.id);

      let deleteUser = httpsCallable(functions, "deleteUser");
      await deleteUser({ uid: this.selectedUserId })
        .then((response) => {
          // Read result of the Cloud Function.

          console.log(response.data);
          const db = getDatabase();
          set(ref(db, "users/" + this.selectedUserId), null);
          this.logData(this.selectedUserId+' Deleted')
          this.getAllUsers();
          this.confirmDialog = false;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    async callDeleteProperty() {
      // console.log(user.id);
      const db = getDatabase();
      set(
        ref(
          db,
          "users/" + this.selectedUserId + "/areas/" + this.selectedAreaId
        ),
        null
      );
      this.logData("Area("+this.selectedAreaId+") deleted from user-" +this.selectedUserId)
      this.getAllUsers();
      this.confirmDialogP = false;
    },
    logData(log){
    
      const db = getDatabase();
      push(ref(db, "log/"), {
               date:moment().format("MM ddd, YYYY hh:mm:ss a"),
               log:log,
               user:{id:auth.currentUser.uid,email:auth.currentUser.email},
                timestamp:moment().unix()
              });
    },
    loadUser(user) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // console.log(user)
      this.editMode = true;
      this.selectedUserId = user.id;
      this.name = user.name;
      this.email = user.email;
      this.phone = user.phone;
      this.userareasfordelete = user.areas;
      // this.roomtypecode = user.roomtypecode;
      // this.assignedArea = user.areaid;
      // this.payableId = user.paybleid;
      // this.receivableId = user.receivabalid;
    },

    // copy(user) {
    //   const db = getDatabase();
    //   push(ref(db, "users/" + user.id + "/areas"), {
    //     areaid: user.areaid,
    //     areaname: user.areaname,
    //     from: "cs",
    //     guestid: user.guestid,
    //     paybleid: user.paybleid,
    //     receivabalid: user.receivabalid,
    //     roomtypecode: user.roomtypecode,
    //     categoryid: user.categoryid,
    //   });
    // },

    editUser() {
      this.loading = true;
      const db = getDatabase();
      update(ref(db, "users/" + this.selectedUserId), {
        name: this.name,
        phone: this.phone,
        // roomtypecode: this.roomtypecode,
        email: this.email,
        // areaid: this.assignedArea,
        // paybleid: this.payableId,
        // receivabalid: this.receivableId,
        // areaname: this.areaName,
      })
        .then(() => {
          this.logData("User updated "+this.email )
          this.getAllUsers();
          this.reset();
          this.loading = false;
          this.editMode = false;
          selectedUserId = "";
          this.loading = false;
        })
        .catch((error) => {
          // The write failed...
        });
    },

    async callGetOwnerInfo() {
      let areaid = this.assignedArea;

      let selectedArea = this.areas.filter(function (area) {
        return area.room == areaid;
      });

      // this.categoryId = selectedArea[0].categoryId;////////////////////no need
      this.areaName = selectedArea[0].room;

      if (!this.editMode) {
        this.isDisable = true;

        this.email = selectedArea[0].email;
        this.name = selectedArea[0].name;
        this.phone = selectedArea[0].phone;
        this.roomtypecode = selectedArea[0].roomtypecode;
        // this.guestId = this.ownerInfo.id;

        this.isDisable = false;
      }
    },
  },
  beforeMount() {
    this.getAllUsers();
  },
  created() {
    this.getAllUsers();
    // this.callGetAreas();
  },
};
</script>
